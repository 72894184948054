import React from 'react'
import { Link, graphql } from 'gatsby'
import Remark from 'remark'
import html from 'remark-html'
import { Helmet } from 'react-helmet'
import Masonry from 'react-masonry-component'

import styles from './style/story.module.scss'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

const masonryOptions = {
  transitionDuration: 0
}

const imagesLoadedOptions = { background: '.my-bg-image-el' }

export const StoryTemplate = ({
  title,
  slug,
  pageContext,
  content,
  contentComponent,
  metaInformation,
  extra,
  stories
}) => {
  const StoryContent = contentComponent || Content
  const convertMarkdownToHtml = markdownString =>
    Remark()
      .use(html)
      .processSync(markdownString?.replace(/\\/g, '  '), (err, file) =>
        err ? { contents: '' } : file
      ).contents

  const category = pageContext.category

  console.log('context', pageContext)

  return (
    <Layout theme="Rattish">
      <div className="page-container">
        {metaInformation &&
        metaInformation.metaDescription &&
        metaInformation.metaDescription.length &&
        metaInformation.metaKeywords &&
        metaInformation.metaKeywords.length ? (
          <Helmet
            title={title + ' | Rättish | Knas Hemma'}
            meta={[
              { name: 'description', content: metaInformation.metaDescription },
              { name: 'keywords', content: metaInformation.metaKeywords }
            ]}
          />
        ) : (
          <Helmet title={title + ' | Rättish | Knas Hemma'} />
        )}
        <article className="page">
          <div className="content">
            {content && (
              <main>
                <div className={styles.Content}>
                  {category && (
                    <>
                      <Link
                        className={styles.Category}
                        to={`/rattish/${category.slug}`}>
                        {category.name}
                      </Link>
                    </>
                  )}

                  {title && <h1 className={styles.Headline}>{title}</h1>}

                  <img
                    className={styles.BackgroundCategory}
                    src={category.featuredimage}
                    alt=""
                  />

                  <StoryContent
                    className="main-content"
                    content={convertMarkdownToHtml(content)}
                  />
                </div>

                {extra.right && (
                  <div className={styles.ContentTwoCol}>
                    <div>
                      <div className={styles.Section}>Fel!</div>
                      <h3>Så här ska det INTE vara</h3>
                      <StoryContent
                        className="main-content"
                        content={convertMarkdownToHtml(extra.wrong)}
                      />
                    </div>
                    <div>
                      <div className={styles.Section}>Rätt!</div>
                      <h3>Så här SKA det vara</h3>
                      <StoryContent
                        className="main-content"
                        content={convertMarkdownToHtml(extra.right)}
                      />
                    </div>
                  </div>
                )}

                <div className={styles.StoryBlockList}>
                  <h3>Berättelser från andra</h3>
                  <div className={styles.BlockList}>
                    <Masonry
                      className={styles.Masonry}
                      elementType={'ul'}
                      options={masonryOptions}
                      imagesLoadedOptions={imagesLoadedOptions}>
                      {stories.edges.map(({ node }) => {
                        if (node.frontmatter.uid !== slug) {
                          return (
                            <li key={node.frontmatter.uid}>
                              {node.frontmatter.storytype === 'story' && (
                                <div className={styles.BlockStory}>
                                  <p>{node.frontmatter.description}</p>
                                  <Link
                                    to={`/rattish/${node.frontmatter.categories}/${node.frontmatter.uid}`}>
                                    Läs hela berättelsen
                                  </Link>
                                </div>
                              )}
                              {node.frontmatter.storytype === 'quoteBlack' && (
                                <div className={styles.BlockQuoteBlack}>
                                  <p>{node.frontmatter.description}</p>
                                </div>
                              )}
                              {node.frontmatter.storytype === 'quoteYellow' && (
                                <div className={styles.BlockQuote}>
                                  <p>{node.frontmatter.description}</p>
                                </div>
                              )}
                            </li>
                          )
                        } else {
                          return null
                        }
                      })}
                    </Masonry>
                  </div>
                </div>
              </main>
            )}
          </div>
        </article>
      </div>
    </Layout>
  )
}

export default ({ data, pageContext }) => {
  const {
    markdownRemark: story,
    fields,
    content,
    allMarkdownRemark: edges
  } = data

  return (
    <StoryTemplate
      contentComponent={HTMLContent}
      pageContext={pageContext}
      title={story.frontmatter.title}
      slug={story.frontmatter.uid}
      content={story.html}
      metaInformation={story.frontmatter.metaInformation}
      extra={story.frontmatter}
      stories={edges}
    />
  )
}

export const StoryQuery = graphql`
  query StoryQuery($id: String!, $cat: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      html
      frontmatter {
        title
        uid
        categories
        right
        wrong
        metaInformation {
          metaDescription
          metaKeywords
        }
      }
    }
    allMarkdownRemark(
      limit: 1000
      filter: { frontmatter: { categories: { in: [$cat] } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            categories
            title
            description
            storytype
            uid
          }
        }
      }
    }
  }
`
